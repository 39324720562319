// react
import React, { useContext, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

// data stubs
import UserContext from '../../contexts/UserContext';
import ProgramContext from '../../contexts/ProgramContext';
import userApi from '../../server/real/endpoints/users';
import locations from '../Countries';

function ShopPageCheckoutAddress(props) {
    const { onAddressChange, region } = props;
    const { country } = region;

    let flatCountries = [];
    for (let i = 0; i < locations.length; i += 1) {
        flatCountries = [...flatCountries, ...locations[i].countries];
    }

    const filteredCountries = flatCountries.filter((data) => data.code === country);
    const geolocationCountry = filteredCountries[0];
    const [addressId, setAddressId] = useState();
    const { program } = useContext(ProgramContext);
    const { user } = useContext(UserContext);
    const [userData, setUserData] = useState(user && user);
    const storageAddress = JSON.parse(localStorage.getItem('summary_data'));
    const [address, setAddress] = useState(storageAddress && storageAddress.shippingAddress ? storageAddress.shippingAddress : user && user.addresses && user.addresses[0]);
    useEffect(() => {
        const userAddress = user.addresses[0];
        userAddress.country = geolocationCountry.title;
        if (!userAddress.fullName || userAddress.fullName.length === 0) {
            userAddress.fullName = user.name;
        }
        setAddressId(storageAddress && storageAddress.shippingAddress ? storageAddress.shippingAddress.id : userAddress.id);
        setAddress(storageAddress && storageAddress.shippingAddress ? storageAddress.shippingAddress : userAddress);
    }, [user]);
    const claimDefaultMail = program && program.defaultEmail;

    const allowMail = program && program.permissions && program.permissions.allowEmailUpdate;

    const defaultMail = () => {
        if (userData.claimEmail === claimDefaultMail) {
            return '';
        }
        return userData.claimEmail;
    };

    const validationEmail = () => {
        const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,30}[.][a-z]{2,5}/g;
        const result = pattern.test(defaultMail() === undefined ? 'help@replink.com' : defaultMail());
        if (!result) {
          return 'form-control is-invalid';
        }
          return 'form-control';
    };

    const addressHandling = (user, e) => {
        const id = e.target.value;
        const address = userApi.getAddress(user, id);
        if (!address.country) {
            address.country = 'USA Continental';
        }
        setAddress(address);
        setAddressId(address.id);
    };
    const zipRegCnd = () => {
        const zipReg = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        if (!zipReg.test(address.postcode)) {
            return 'form-control is-invalid';
        }
        return 'form-control';
    };
    const zipRegUs = () => {
        const zipReg = /(^\d{5}$)|(^\d{5}[ -]?\d{4}$)/;
        if (!zipReg.test(address.postcode)) {
            return 'form-control is-invalid';
        }
        return 'form-control';
    };

    const phoneLength = () => {
        if ((address && address.country && address.country.indexOf('USA') !== -1) || (address && address.country === 'Canada')) {
        return 18;
    }
    return 50;
    };

    const zipRegex = () => {
        if (address && address.country && address.country.indexOf('USA') !== -1) {
            return zipRegUs();
        }
        if (address.country === 'Canada') {
            return zipRegCnd();
        }
        return 'form-control';
    };
    const addressValidate = () => {
        if (address && address.address1 && address.address1.length > 1) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const countryValidate = () => {
        if ((address && address.country && address.country.length > 1) && (address && address.country && address.country && address.country.indexOf('Select Country...') === -1)) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const phoneValidate = () => {
        const number = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        if (address && address.phone && address.phone.length > 1 && number.test(address.phone)) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const fullnameValidate = () => {
        if (address && address.fullName && address.fullName.length > 1) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const cityValidate = () => {
        if (address && address.townCity && address.townCity.length > 1) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const stateValidate = () => {
        if (address && address.stateCounty && address.stateCounty.length > 1) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const checkValidUs = () => {
        if (program.type === 'claim') {
            if (addressValidate() === 'form-control'
                && countryValidate() === 'form-control'
                && phoneValidate() === 'form-control'
                && zipRegex() === 'form-control'
                && fullnameValidate() === 'form-control'
                && cityValidate() === 'form-control'
                && stateValidate() === 'form-control'
                && validationEmail() === 'form-control') {
                return true;
            }
        return false;
        }
            if (addressValidate() === 'form-control'
                && countryValidate() === 'form-control'
                && phoneValidate() === 'form-control'
                && zipRegex() === 'form-control'
                && fullnameValidate() === 'form-control'
                && cityValidate() === 'form-control'
                && stateValidate() === 'form-control') {
                return true;
            }
        return false;
    };

    const checkValid = () => {
        if (program.type === 'claim') {
        if (addressValidate() === 'form-control' && countryValidate() === 'form-control' && phoneValidate() === 'form-control' && zipRegex() === 'form-control' && fullnameValidate() === 'form-control') {
            return true;
        }
        return false;
        }
        if (addressValidate() === 'form-control' && countryValidate() === 'form-control' && phoneValidate() === 'form-control' && zipRegex() === 'form-control' && fullnameValidate() === 'form-control' && validationEmail() === 'form-control') {
            return true;
        }
        return false;
    };
    useEffect(() => {
        onAddressChange(address, address && address.country && address.country.indexOf('USA') !== -1 ? checkValidUs() : checkValid(), allowMail ? defaultMail() : claimDefaultMail);
    }, [address, validationEmail()]);

    const keyChanged = (key, e) => {
        if (storageAddress) {
            localStorage.removeItem('summary_data');
        }
        const newaddress = { ...address };
        newaddress[key] = e.target.value;
        setAddress(newaddress);
        addressValidate();
        validationEmail();
    };

    const customKeyChanged = (key, e) => {
        const newaddress = { ...address };
        if (key === 'customField1') newaddress[key] = { label: program?.customField1, value: e.target.value };
        if (key === 'customField2') newaddress[key] = { label: program?.customField2, value: e.target.value };
        setAddress(newaddress);
        addressValidate();
        validationEmail();
    };

    const mailChanged = (key, e) => {
        const userMail = { ...userData };
        userMail[key] = e.target.value;
        setUserData(userMail);
        validationEmail();
    };
    let isDisabled;
    if (program && program.permissions && program.permissions.allowAddressManagement === false) {
        isDisabled = true;
    }
    return (
        <React.Fragment>
            <h3 className="card-title"><FormattedMessage id="checkout_shipping" defaultMessage="Shipping Details" /></h3>
            <div className="form-group">
                {user.addresses.length > 0 && (
                    <div className="form-group">
                        <label htmlFor="checkout-country">
                            <FormattedMessage id="checkout_myaddresses" defaultMessage="My Addresses" />
                        </label>
                        <select
                            id="checkout-country"
                            className="form-control"
                            value={addressId}
                            onChange={(e) => {
                                addressHandling(user, e);
                            }}
                        >
                            {program && program.permissions.allowAddressManagement === true && (<option key="new-address" value={0}>New Address</option>)}
                            {user.addresses && user.addresses.map((address) => (
                                <option key={address.id} value={address.id}>{`${(!address.address1 || address.address1 === 'undefined') ? 'Default' : address.address1}`}</option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
            <React.Fragment>
                <div className="form-group">
                    <label htmlFor="name">
                        <FormattedMessage id="checkout_fullname" defaultMessage="Full Name" />
                        <span style={{ color: 'red' }}>*</span>
                    </label>
                    <FormattedMessage id="address_nameSurnamePlaceholder" defaultMessage="Name Surname">
                        {(placeholder) => (
                            <input
                                type="text"
                                className={fullnameValidate()}
                                id="checkout-company-name"
                                placeholder={placeholder}
                                maxLength="50"
                                disabled={isDisabled}
                                onChange={(e) => keyChanged('fullName', e)}
                                value={address && address.fullName}
                            />
                                )}
                    </FormattedMessage>
                </div>
                <div className="form-group">
                    <label htmlFor="checkout-company-name">
                        <FormattedMessage id="checkout_company" defaultMessage="Company Name" />
                        {' '}
                        <span className="text-muted"><FormattedMessage id="checkout_mutedoptional" defaultMessage="(Optional)" /></span>
                    </label>
                    <FormattedMessage id="address_companyPlaceholder" defaultMessage="Company Name">
                        {(placeholder) => (
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-company-name"
                                placeholder={placeholder}
                                maxLength="50"
                                disabled={isDisabled}
                                onChange={(e) => keyChanged('companyName', e)}
                                value={address && address.companyName}
                            />
                                )}
                    </FormattedMessage>
                </div>
                {program && program.type === 'claim' && allowMail && (
                <div className="form-group">
                    <label htmlFor="claim-mail">
                        <FormattedMessage id="checkout_claimEmail" defaultMessage="Email Address" />
                        <span style={{ color: 'red' }}>*</span>
                    </label>
                    <FormattedMessage id="address_claimEmailPlaceholder" defaultMessage="Email Address">
                        {(placeholder) => (
                            <input
                                type="text"
                                className={validationEmail()}
                                id="claim-mail"
                                placeholder={placeholder}
                                maxLength="50"
                                disabled={isDisabled}
                                onChange={(e) => mailChanged('claimEmail', e)}
                                value={defaultMail()}
                            />
                                )}
                    </FormattedMessage>
                </div>
)}
                <div className="form-group">
                    <label htmlFor="checkout-country">
                        <FormattedMessage id="checkout_country" defaultMessage="Country" />
                        <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="checkout-country"
                        placeholder="Country"
                        maxLength="50"
                        disabled
                        value={geolocationCountry && geolocationCountry.title}
                        onChange={(e) => keyChanged('country', e)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="checkout-street-address1">
                        <FormattedMessage id="checkout_address1" defaultMessage="Address 1" />
                        <span style={{ color: 'red' }}>*</span>
                    </label>
                    <FormattedMessage id="address_address1Placeholder" defaultMessage="Address">
                        {(placeholder) => (
                            <input
                                type="text"
                                className={addressValidate()}
                                id="checkout-street-address 1"
                                placeholder={placeholder}
                                maxLength="50"
                                disabled={isDisabled}
                                onChange={(e) => keyChanged('address1', e)}
                                value={address && address.address1}
                            />
                                    )}
                    </FormattedMessage>
                </div>
                <div className="form-group">
                    <label htmlFor="checkout-street-address2">
                        <FormattedMessage id="checkout_address2" defaultMessage="Address 2- optional" />
                    </label>
                    <FormattedMessage id="address_address2Placeholder" defaultMessage="You Can Continue Here if Necessary">
                        {(placeholder) => (
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address 2"
                                placeholder={placeholder}
                                maxLength="50"
                                disabled={isDisabled}
                                onChange={(e) => keyChanged('address2', e)}
                                value={address && address.address2}
                            />
                                )}
                    </FormattedMessage>
                </div>
                {(address && address.country && address.country.indexOf('USA') === -1) ? (
                    <React.Fragment>
                        <div className="form-group">
                            <label htmlFor="checkout-street-address3">
                                <FormattedMessage id="checkout_address3" defaultMessage="Address 3" />
                            </label>
                            <FormattedMessage id="address_address3Placeholder" defaultMessage="You Can Continue Here if Necessary">
                                {(placeholder) => (
                                    <input
                                        type="text"
                                        className="form-control 3"
                                        id="checkout-street-address"
                                        placeholder={placeholder}
                                        maxLength="50"
                                        disabled={isDisabled}
                                        onChange={(e) => keyChanged('address3', e)}
                                        value={address && address.address3}
                                    />
                                        )}
                            </FormattedMessage>
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-street-address4">
                                <FormattedMessage id="checkout_address4" defaultMessage="Address 4" />
                            </label>
                            <FormattedMessage id="address_address4Placeholder" defaultMessage="You Can Continue Here if Necessary">
                                {(placeholder) => (
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="checkout-street-address 4"
                                        placeholder={placeholder}
                                        maxLength="50"
                                        disabled={isDisabled}
                                        onChange={(e) => keyChanged('address4', e)}
                                        value={address && address.address4}
                                    />
                                        )}
                            </FormattedMessage>
                        </div>
                    </React.Fragment>
                ) : ''}
                {(address && address.country && address.country.indexOf('USA') !== -1) ? (
                    <React.Fragment>
                        <div className="form-group">
                            <label htmlFor="checkout-city">
                                <FormattedMessage id="checkout_towncity" defaultMessage="Town / City" />
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input type="text" className={cityValidate()} id="checkout-city" disabled={isDisabled} onChange={(e) => keyChanged('townCity', e)} value={address && address.townCity} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-state">
                                <FormattedMessage id="checkout_statecounty" defaultMessage="State / County" />
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <FormattedMessage id="address_statePlaceholder" defaultMessage="e.g. WY or QC">
                                {(placeholder) => (
                                    <input type="text" placeholder={placeholder} maxLength="2" className={stateValidate()} id="checkout-state" disabled={isDisabled} onChange={(e) => keyChanged('stateCounty', e)} value={address && address.stateCounty} />
                                        )}
                            </FormattedMessage>
                        </div>
                    </React.Fragment>
                ) : ''}
                {address && address.country === 'Canada' && (
                    <div className="form-group">
                        <label htmlFor="checkout-postcode">
                            <FormattedMessage id="checkout_postcodezip" defaultMessage="Postcode / ZIP" />
                            <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input type="text" className={zipRegCnd()} maxLength="7" id="checkout-postcode" disabled={isDisabled} onChange={(e) => keyChanged('postcode', e)} value={address && address.postcode} />
                    </div>
                )}
                {(address && address.country && address.country.indexOf('USA') !== -1) && (
                    <div className="form-group">
                        <label htmlFor="checkout-postcode">
                            <FormattedMessage id="checkout_postcodezip" defaultMessage="Postcode / ZIP" />
                            <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input type="text" className={zipRegUs()} maxLength="10" id="checkout-postcode" disabled={isDisabled} onChange={(e) => keyChanged('postcode', e)} value={address && address.postcode} />
                    </div>
                )}

                {(address && address.country && address.country.indexOf('USA') === -1) && (address && address.country !== 'Canada') && (
                    <div className="form-group">
                        <label htmlFor="checkout-postcode">
                            <FormattedMessage id="checkout_postcodezip" defaultMessage="Postcode / ZIP" />
                        </label>
                        <input type="text" className="form-control" id="checkout-postcode" maxLength="9" disabled={isDisabled} onChange={(e) => keyChanged('postcode', e)} value={address && address.postcode} />
                    </div>
                )}
                <div className="form-row">
                    <div className="form-group col-md-9">
                        <label htmlFor="checkout-email">
                            <FormattedMessage id="checkout_phone" defaultMessage="Phone Number" />
                            <span style={{ color: 'red' }}>*</span>
                        </label>
                        <FormattedMessage id="address_phonePlaceholder" defaultMessage="Please Enter Your Phone Number">
                            {(placeholder) => (
                                <input
                                    type="text"
                                    className={phoneValidate()}
                                    id="checkout-phone"
                                    placeholder={placeholder}
                                    onChange={(e) => keyChanged('phone', e)}
                                    disabled={isDisabled}
                                    maxLength={phoneLength()}
                                    value={address && address.phone}
                                />
                                    )}
                        </FormattedMessage>
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="checkout-phone">
                            <FormattedMessage id="checkout_ext" defaultMessage="Ext" />
                        </label>
                        <FormattedMessage id="address_phoneExtPlaceholder" defaultMessage="Ext">
                            {(placeholder) => (
                                <input type="text" className="form-control" id="checkout-phone-ext" placeholder={placeholder} disabled={isDisabled} onChange={(e) => keyChanged('phoneExt', e)} value={address && address.phoneExt} />
                                    )}
                        </FormattedMessage>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-9">
                        <label htmlFor="checkout-email">
                            <FormattedMessage id="checkout_altphone" defaultMessage="Alternative Phone Number" />
                        </label>
                        <FormattedMessage id="address_altPhonePlaceholder" defaultMessage="Alternative Phone Number">
                            {(placeholder) => (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-alt-phone"
                                    placeholder={placeholder}
                                    disabled={isDisabled}
                                    onChange={(e) => keyChanged('alternativePhone', e)}
                                    value={address && address.alternativePhone}
                                />
                                    )}
                        </FormattedMessage>
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="Alternativecheckout-phone">
                            <FormattedMessage id="checkout_ext" defaultMessage="Ext" />
                        </label>
                        <FormattedMessage id="address_altPhoneExtPlaceholder" defaultMessage="Ext">
                            {(placeholder) => (
                                <input type="text" className="form-control" id="checkout-alt-phone-ext" placeholder={placeholder} disabled={isDisabled} onChange={(e) => keyChanged('alternativePhoneExt', e)} value={address && address.alternativePhoneExt} />
                                    )}
                        </FormattedMessage>
                    </div>
                </div>
                {program?.customField1 && Object.keys(program?.customField1).length > 0 && (
                <div className="form-group">
                    <label htmlFor="checkout-custom1">
                        {program?.customField1}
                    </label>
                    <input type="text" className="form-control" id="checkout-custom1" disabled={isDisabled} onChange={(e) => customKeyChanged('customField1', e)} value={address && address.customField1?.[1]} />
                </div>
                )}
                {program?.customField2 && Object.keys(program?.customField2).length > 0 && (
                <div className="form-group">
                    <label htmlFor="checkout-custom2">
                        {program?.customField2}
                    </label>
                    <input type="text" className="form-control" id="checkout-custom2" disabled={isDisabled} onChange={(e) => customKeyChanged('customField2', e)} value={address && address.customField2?.[1]} />
                </div>
                )}
            </React.Fragment>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    region: state.region,
    locale: state.locale,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckoutAddress);
